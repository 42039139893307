import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import ButtonsResult from "./ButtonsResult";
import Header from "../components/Header/VersionPatchnotes";

let renderCount = 0;

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

export default function UploadPatchNotes() {
  const { handleSubmit, register, reset } = useForm();
  const [uploadError, setUploadError] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [isValidFiles, setIsValidFiles] = useState(true);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);

  const getPresignedUrl = async (filename, filetype) => {
    const response = await axios.get(
      "https://5067ra1ruf.execute-api.ap-southeast-1.amazonaws.com/prod/upload",
      {
        params: {
          filename,
          filetype,
        },
      }
    );
    return response.data.uploadUrl;
  };

  const handleFileUpload = async (file) => {
    try {
      const url = await getPresignedUrl(file.name, file.type);
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      setIsUploadSuccessful(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError("Error uploading file. Please try again later.");
    }
  };

  const handleContentDisplay = (e) => {
    const allowedFileName = "patchnotes.html";
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.name !== allowedFileName) {
        setUploadError('Please upload a file named "patchnotes.html".');
        setIsValidFiles(false);
      } else {
        setUploadError("");
        setIsValidFiles(true);

        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          console.log("File content:", fileContent);
          setFileContent(fileContent);
        };
        reader.readAsText(e.target.files[0]);
      }
    }
  };

  const clearFiles = () => {
    reset({
      patchFile: undefined,
    });
  };

  const onSubmit = async (data) => {
    try {
      if (data.patchFile && data.patchFile.length > 0) {
        await handleFileUpload(data.patchFile[0]);
      } else {
        setUploadError("Please select a file to upload.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError(
        'Error uploading file. Make sure file named is "patchnotes.html"'
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <Header renderCount={renderCount} />
        <div className="container">
          <section className="">
            <div className="text-[#593F3F] w-full text-left mt-6">
              <label>Upload patch notes file here</label>
              <input
                name="patchFile"
                className="input !border-0 !px-0"
                type="file"
                accept=".txt"
                ref={register}
                onChange={handleContentDisplay}
              />
            </div>
          </section>
        </div>

        <div className=" text-[#593F3F] text-lg font-semibold text-left pr-4">
          File Content:
          {fileContent && (
            <div className="file-content font-extralight">{fileContent}</div>
          )}
        </div>

        {uploadError && <div className="error-message">{uploadError}</div>}
        {isUploadSuccessful && (
          <div className="success-message">Upload successful!</div>
        )}

        <ButtonsResult
          disabled={!setIsValidFiles}
          onClear={clearFiles}
          reset={reset}
        />
      </form>
    </ThemeProvider>
  );
}
