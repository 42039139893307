import React, { useState } from "react";
import AWS from "aws-sdk";
import * as XLSX from "xlsx";

const S3_BUCKET = "terraquest-items";
const REGION = "ap-southeast-1";
const ACCESS_KEY = "AKIA4QBGOHJV6EZIBMW3";
const SECRET_ACCESS_KEY = "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";

const s3 = new AWS.S3({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const UploadCostume = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [dataXLS, setDataXLS] = useState([]);
  const [modifiedExcelUrl, setModifiedExcelUrl] = useState("");
  const [originalExcelFileName, setOriginalExcelFileName] = useState("");

  const handleFileInputChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleExcelInputChange = (e) => {
    const file = e.target.files[0];
    setExcelFile(file);
    setOriginalExcelFileName(file.name);
  };

  const handleUpload = async () => {
    const uploadPromises = [];
    const fileUrls = {};

    Array.from(selectedFiles).forEach((file) => {
      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
      };

      const uploadPromise = new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const fileName = file.name;
            const idFromFileName = parseInt(fileName.split(".")[0], 10);
            if (!isNaN(idFromFileName)) {
              fileUrls[idFromFileName] = data.Location;
            }
            resolve(data);
          }
        });
      });

      uploadPromises.push(uploadPromise);
    });

    try {
      await Promise.all(uploadPromises);
      console.log("File URLs:", fileUrls);
      processExcelFile(fileUrls);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const deepClone = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj; // If not an object, return the original value
    }

    const clonedObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clonedObj[key] = deepClone(obj[key]); // Recursively clone nested objects
      }
    }

    return clonedObj;
  };

  const processExcelFile = (fileUrls) => {
    const reader = new FileReader();
    reader.readAsBinaryString(excelFile);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(sheet, { defval: "" });

      // Add the Link to each row and make sure it appears in the last column
      jsonData = jsonData.map((row) => {
        const id = row["ID_no_item"];
        const link = id in fileUrls ? fileUrls[id] : "";
        return { ...row, Link: link };
      });

      // Create a new sheet from the updated jsonData
      const newSheet = XLSX.utils.json_to_sheet(jsonData);

      // Ensure the "Link" header is added as the last column
      const range = XLSX.utils.decode_range(newSheet["!ref"]);
      const linkColumnIndex = range.e.c;
      newSheet[XLSX.utils.encode_cell({ r: 0, c: linkColumnIndex })] = {
        t: "s",
        v: "Link",
      };
      // Copy styles from the original sheet to the new sheet
      newSheet["!cols"] = deepClone(sheet["!cols"]);
      newSheet["!rows"] = deepClone(sheet["!rows"]);
      newSheet["!merges"] = deepClone(sheet["!merges"]);
      Object.keys(sheet).forEach((cell) => {
        if (cell[0] === "!") return; // Skip special properties
        if (!newSheet[cell]) newSheet[cell] = {};
        // Clone the entire style object deeply
        newSheet[cell].s = deepClone(sheet[cell].s); // Copy the style
        newSheet[cell].z = sheet[cell].z; // Copy the format
      });

      // Append the new sheet to a new workbook
      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, newSheet, sheetName);

      // Generate the binary data for the new workbook
      const wbout = XLSX.write(newWorkbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      setModifiedExcelUrl(url);
    };
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <div className="my-5">
      <div className="text-[#593F3F] my-3">React S3 File Upload</div>

      <div className="!text-[#593F3F] py-4">
        <label className="text-left">Your Excel File:</label>
        <br />
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleExcelInputChange}
          className="text-[#593F3F]"
        />
      </div>

      <div className="!text-[#593F3F] py-4">
        <label className="text-left">Your Image Files (PNG, WEBP, SVG):</label>
        <br />
        <input
          type="file"
          accept=".png, .webp, .svg, .jpg"
          onChange={handleFileInputChange}
          multiple
          className="text-[#593F3F]"
        />
      </div>

      <button
        onClick={handleUpload}
        className="bg-[#593f3f] hover:bg-[#F8B641] text-white font-bold py-2 px-4 rounded"
      >
        Upload to S3
      </button>

      {dataXLS.length > 0 && (
        <div className="mt-4">
          <h3>Modified Excel Data:</h3>
          {/* <pre>{JSON.stringify(dataXLS, null, 2)}</pre> */}
        </div>
      )}

      {modifiedExcelUrl && (
        <div className="mt-4">
          <a
            href={modifiedExcelUrl}
            download={`modified_${originalExcelFileName}`}
            className="bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Download Modified Excel File
          </a>
        </div>
      )}
    </div>
  );
};

export default UploadCostume;
