import React from "react";

export default function Sidebar() {
  const options = [
    {
      name: "Inventory Items",
      link: "/",
    },
    // {
    //   name: "Inventory Items",
    //   link: "/inventory",
    // },
    {
      name: "Game Version",
      link: "/gameversion",
    },
    {
      name: "Upload S3",
      link: "/costumeData",
    },
    {
      name: "Patch Notes",
      link: "/patchnotes",
    },
    {
      name: "Upload WebGL",
      link: "/webGL",
    },
  ];

  return (
    <div className="flex h-screen flex-col justify-between border-e-2 bg-white w-1/6 fixed border-[#593F3F]">
      <div className="px-2 py-2">
        <span className="grid h-12 w-36 place-content-center rounded-lg  text-xs m-auto py-10">
          <img src="/TQ-admin.png" alt="logo" />
        </span>

        <ul className="mt-6 space-y-1">
          {options?.map((option) => (
            <li>
              <a
                href={option.link}
                className="block rounded-lg  text-sm p-3 font-medium text-[#593F3F] hover:text-blue-600"
              >
                {option.name}
              </a>
            </li>
          ))}

          {/* <li>
                    <details className="group [&_summary::-webkit-details-marker]:hidden">
                        <summary
                            className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                        >
                        <span className="text-sm font-medium"> Teams </span>

                        <span
                            className="shrink-0 transition duration-300 group-open:-rotate-180"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            >
                            <path
                                fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                            </svg>
                        </span>
                        </summary>

                        <ul className="mt-2 space-y-1 px-4">
                        <li>
                            <a
                            href=""
                            className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                            >
                            Banned Users
                            </a>
                        </li>

                        <li>
                            <a
                            href=""
                            className="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                            >
                            Calendar
                            </a>
                        </li>
                        </ul>
                    </details>
                    </li> */}
        </ul>
      </div>

      {/* button at most bottom sidebar */}
      {/* <div className="sticky inset-x-0 bottom-0 border-t border-gray-100"> Logout Button </div> */}
    </div>
  );
}
