import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ renderCount }) => (
  <>
    <h1 className="h1 text-3xl text-[#593F3F] mb-3">
      Add New Items in Storage
    </h1>
  </>
);
