import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useForm, Controller } from "react-hook-form";
import Header from "../components/Header/Header";
import { Client } from "@heroiclabs/nakama-js";
import {
  RadioGroup,
  FormControlLabel,
  ThemeProvider,
  Radio,
  createMuiTheme,
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import ButtonsResult from "./ButtonsResult";

let renderCount = 0;

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const defaultValues = {
  Native: "",
  Select: "",
  RadioGroup: "",
};

export default function Home() {
  const { handleSubmit, register, reset, control } = useForm({ defaultValues });
  const [data, setData] = useState(null);

  // set ssl
  const [client, setClient] = useState(null);
  const [session, setSession] = useState(null);

  // end
  // client

  // socket
  useEffect(() => {
    // Initialize Nakama client
    const useSSL = true; // Enable if server is run with an SSL certificate.
    let client = new Client(
      "YBmC6UK0BsS2KEOf",
      "terraquest.us-east1-c.nakamacloud.io",
      443,
      useSSL
    ); // Replace with your actual server key
    // var client = new nakamajs.Client("YBmC6UK0BsS2KEOf", "terraquest.us-east1-c.nakamacloud.io", 443);
    // client.ssl = true;

    client
      .authenticateCustom("adminTerraquest")
      .then(async (session) => {
        // console.log("Authenticated:", session);

        setSession(session);

        client.timeout = 10000;
        const socket = client.createSocket();
        // console.log("Socket Connected:", socket);

        const appearOnline = true;

        await socket
          .connect(session, appearOnline)

          .then(async (res) => {
            // console.log("Session:", res);

            return;
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        console.error("Authentication error:", error);
      });

    setClient(client);

    // console.log("data", data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitTest = () => {
    // Logic for submitting the test
    // This function will be executed when the "submit" button is clicked
    console.log("Test submitted!" + JSON.stringify(data, null, 2));
  };

  const onSubmit = async (data) => {
    // Create the "interaction" object and group specific properties within it
    data.interaction = {
      isDeletable: data.isDeletable,
      isDropable: data.isDropable,
      equip: {
        isCostume: data.isCostume,
        isTool: data.isTool,
      },
    };

    // Create the "interaction" object and group specific properties within it
    data.wear = {
      player: data.player,
      pet: data.pet,
    };

    // Delete the individual properties from the main object

    delete data.player;
    delete data.pet;
    delete data.isCostume;
    delete data.isTool;
    delete data.isDeletable;
    delete data.isDropable;

    setData(data);

    // process the read first before writeStorage

    client
      .listStorageObjects(
        session,
        data.Collection,
        session.user_id,
        undefined,
        undefined
      )
      .then((result) => {
        console.log("List storage successful:", result.objects[0]);

        if (result.objects[0] !== undefined || result.objects[0] !== null) {
          let previousData = result.objects[0];

          if (previousData) {
            console.log("previousData", JSON.stringify(previousData));
            console.log("newData", JSON.stringify(data));

            // Access the value property of previousData
            let previousDataValue = previousData.value;

            if (previousDataValue.collection) {
              let appendExistData = previousDataValue.collection;
              let newData = data;

              appendExistData.push(newData);
              console.log("appendExistData", appendExistData);

              let data1 = {
                collection: appendExistData,
              };

              client
                .writeStorageObjects(session, [
                  {
                    collection: data.Collection,
                    key: data.Key,
                    permissionRead: 2,
                    permissionWrite: 1,
                    userId: undefined,
                    value: data1,
                  },
                ])
                .then((writeResult) => {
                  console.log(
                    "Write storage successful overwrite:",
                    writeResult
                  );
                })
                .catch((writeError) => {
                  console.error("Storage write request failed:", writeError);
                });
            } else {
              let oldData = previousData.value;

              // Check if 'value' is an array, if not, initialize it as an empty array
              if (!Array.isArray(previousDataValue)) {
                previousDataValue = [];
              }

              // // Push the newData object to the 'value' array
              previousDataValue.push(data, oldData);
              data = {
                collection: [previousDataValue],
              };

              client
                .writeStorageObjects(session, [
                  {
                    collection: data.Collection,
                    key: data.Key,
                    permissionRead: 2,
                    permissionWrite: 1,
                    userId: undefined,
                    value: data,
                  },
                ])
                .then((writeResult) => {
                  console.log(
                    "Write storage successful overwrite:",
                    writeResult
                  );
                })
                .catch((writeError) => {
                  console.error("Storage write request failed:", writeError);
                });
            }
          } else {
            console.log("previousData not found");

            const writeRequest = {
              collection: data.Collection,
              key: data.Key,
              permissionRead: 2, // Set the read permission as needed (2 means "Public Read").
              permissionWrite: 1, // Set the write permission as needed (1 means "Owner Write").
              userId: undefined,
              value: {
                collection: [data],
              },
            };

            client
              .writeStorageObjects(session, [writeRequest])
              .then((result) => {
                console.log("Write storage successful:", result);
                // const objects = result[0].objects;
                // console.log("Objects:", objects);
              })
              .catch((error) => {
                console.error("Storage write request failed:", error);
              });
          }
        }
      })
      .catch((error) => {
        console.error("List storage request failed:", error);
      });
  };

  renderCount++;

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <Header renderCount={renderCount} />
        <div className="container">
          <section>
            <label>Collection:</label>
            <input name="Collection" className="input" ref={register} />
          </section>

          <section>
            <label>Key:</label>
            <input name="Key" className="input" ref={register} />
          </section>

          <section>
            <label>Name:</label>
            <input name="Name" className="input" ref={register} />
          </section>

          <section>
            <label>ID:</label>
            <input name="ID" className="input" ref={register} />
          </section>

          <div className="subcontainer">
            <section>
              <label>Deletable</label>
              <Controller
                as={
                  <RadioGroup aria-label="deletable">
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                }
                name="isDeletable"
                control={control}
              />
            </section>
          </div>

          <section>
            <label>Description:</label>
            <textarea
              name="Description"
              className="input inputTextarea"
              ref={register}
            />
          </section>
        </div>
        <ButtonsResult {...{ data, reset, defaultValues, submitTest }} />
      </form>
    </ThemeProvider>
  );
}
