import React from "react";

const ButtonsResult = ({ allData, reset, defaultValues, onClear }) => {
  return (
    <>
      {allData && (
        <pre style={{ textAlign: "left", color: "white" }}>
          {JSON.stringify(allData, null, 2)}
        </pre>
      )}

      <button
        className="button !bg-[#E57028]"
        type="button"
        onClick={() => {
          reset(defaultValues);
          if (onClear) onClear(); // Clears the file inputs
        }}
      >
        Reset Form
      </button>
      <button className="button !bg-blue-600 mb-10">submit</button>
    </>
  );
};

export default ButtonsResult;
