import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import ButtonsResult from "./ButtonsResult";
import Header from "../components/Header/VersionManagerHeader";
import LinearProgress from "@material-ui/core/LinearProgress";

let renderCount = 0;

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

export default function UploadVersion() {
  const { handleSubmit, register, reset } = useForm();
  const [currentVersion, setCurrentVersion] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [buildProgress, setBuildProgress] = useState(0);
  const [versionProgress, setVersionProgress] = useState(0);
  const [hasBuildFileSelected, setHasBuildFileSelected] = useState(false);
  const [buildMacProgress, setBuildMacProgress] = useState(0);
  const [hasBuildMacFileSelected, setHasBuildMacFileSelected] = useState(false);

  const [hasVersionFileSelected, setHasVersionFileSelected] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const getPresignedUrl = async (filename, filetype) => {
    const response = await axios.get(
      "https://5067ra1ruf.execute-api.ap-southeast-1.amazonaws.com/prod/upload",
      {
        params: {
          filename,
          filetype,
        },
      }
    );
    return response.data.uploadUrl;
  };

  const handleFileUpload = async (file, setProgress) => {
    const url = await getPresignedUrl(file.name, file.type);

    await axios.put(url, file, {
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
      headers: {
        "Content-Type": file.type,
      },
    });
  };

  const clearFiles = () => {
    reset({
      buildFile: undefined,
      versionFile: undefined,
      buildMacFile: undefined,
    });
  };

  const onSubmit = async (data) => {
    setSubmitClicked(true); // Submit button was clicked

    const buildFileSelected = data.buildFile && data.buildFile.length > 0;
    const buildMacFileSelected =
      data.buildMacFile && data.buildMacFile.length > 0;
    const versionFileSelected = data.versionFile && data.versionFile.length > 0;

    setHasBuildFileSelected(buildFileSelected);
    setHasBuildMacFileSelected(buildMacFileSelected);
    setHasVersionFileSelected(versionFileSelected);

    if (!buildFileSelected && !versionFileSelected) {
      setUploadError("No files selected. Please select files to upload.");
      return; // Early return if no files are selected
    }

    setUploadSuccess(false); // Reset the success message
    setUploadError(""); // Reset the error message

    try {
      if (buildFileSelected) {
        await handleFileUpload(data.buildFile[0], setBuildProgress);
      }

      if (buildMacFileSelected) {
        await handleFileUpload(data.buildMacFile[0], setBuildMacProgress);
      }

      if (versionFileSelected) {
        await handleFileUpload(data.versionFile[0], setVersionProgress);

        // After successfully uploading the version.txt, fetch the latest version
        await fetchVersion();
      }

      // If both uploads succeed, set success message
      setUploadSuccess(true);
    } catch (error) {
      // If there's an error, set the error message
      setUploadError(
        "An error occurred while uploading the files. Please try again."
      );
    }
  };

  const fetchVersion = async () => {
    try {
      const response = await axios.get(
        "http://update.terraquest.xyz.s3-website-ap-southeast-1.amazonaws.com/version.txt"
      );
      setCurrentVersion(response.data);
    } catch (error) {
      console.error("Error fetching the current version", error);
      // Handle error scenario, possibly setting some error state
    }
  };

  useEffect(() => {
    fetchVersion();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <Header renderCount={renderCount} />
        <div className="container">
          <section className="">
            <div
              className="text-[#593F3F] w-full text-left mt-6"
              style={{
                border: "1px solid #593F3F",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <label>Upload build.zip:</label>
              <input
                name="buildFile"
                className="input !border-0 !px-0"
                type="file"
                accept=".zip"
                ref={register}
                onChange={(e) => {
                  if (e.target.files[0].name !== "build.zip") {
                    alert(
                      "Please ensure that the file selected is named build.zip."
                    );
                    e.target.value = null;
                  }
                }}
              />
            </div>

            {/* Conditionally render build.zip progress section */}
            {hasBuildFileSelected && submitClicked && (
              <div className="progress-section">
                <label>Build.zip Upload Progress:</label>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${buildProgress}%` }}
                  >
                    {buildProgress > 0 ? `${buildProgress}%` : ""}
                  </div>
                </div>
              </div>
            )}

            <div
              className="text-[#593F3F] w-full text-left mt-6"
              style={{
                border: "1px solid #593F3F",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <label>Upload build-mac.zip:</label>
              <input
                name="buildMacFile"
                className="input !border-0 !px-0"
                type="file"
                accept=".zip"
                ref={register}
                onChange={(e) => {
                  if (e.target.files[0].name !== "build-mac.zip") {
                    alert(
                      "Please ensure that the file selected is named build-mac.zip."
                    );
                    e.target.value = null;
                  } else {
                    setHasBuildMacFileSelected(true);
                  }
                }}
              />
            </div>

            {hasBuildMacFileSelected && submitClicked && (
              <div className="progress-section">
                <label>Build-mac.zip Upload Progress:</label>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${buildMacProgress}%` }}
                  >
                    {buildMacProgress > 0 ? `${buildMacProgress}%` : ""}
                  </div>
                </div>
              </div>
            )}

            <div
              className="text-[#593F3F] w-full text-left mt-6"
              style={{
                border: "1px solid #593F3F",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <label>🗒️ Current Version:</label>
              <div>
                {uploadSuccess
                  ? `Updated to ${currentVersion}`
                  : currentVersion}
              </div>
              <label>📝 Upload version.txt:</label>
              <input
                name="versionFile"
                className="input !border-0 !px-0"
                type="file"
                accept=".txt"
                ref={register}
                onChange={(e) => {
                  if (e.target.files[0].name !== "version.txt") {
                    alert(
                      "Please ensure that the file selected is named version.txt."
                    );
                    e.target.value = null;
                  }
                }}
              />
            </div>

            {/* Conditionally render version.txt progress section */}
            {hasVersionFileSelected && submitClicked && (
              <div className="progress-section">
                <label>Version.txt Upload Progress:</label>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${versionProgress}%` }}
                  >
                    {versionProgress > 0 ? `${versionProgress}%` : ""}
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>

        {/* Display success or error message */}
        {uploadSuccess && (
          <div className="success-message">Files uploaded successfully!</div>
        )}
        {uploadError && <div className="error-message">{uploadError}</div>}
        <ButtonsResult onClear={clearFiles} reset={reset} />
      </form>
    </ThemeProvider>
  );
}
