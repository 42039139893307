import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ renderCount }) => (
  <>
    <h1 className="h1 text-3xl text-[#593F3F] mb-3">Upload New Game Version</h1>
    <p
      style={{ fontSize: 14, lineHeight: 1.3 }}
      className="text-[#593F3F] py-3"
    >
      File names MUST be build.zip and version.txt
      <br />
      If you want to trigger the update of the version later, you can upload
      build.zip first, then update version.txt later.
    </p>
  </>
);
