import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ renderCount }) => (
  <>
    <h1 className="h1 text-3xl text-[#593F3F] mb-3">Upload New Patch Notes</h1>
    <p
      style={{ fontSize: 14, lineHeight: 1.3 }}
      className="text-[#593F3F] py-3"
    >
      Please make sure file named is "patchnotes.html" when uploading
    </p>
  </>
);
