import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header/InventoryHeader";
import { Client } from "@heroiclabs/nakama-js";
import * as XLSX from "xlsx";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import ButtonsResult from "./ButtonsResult";

let renderCount = 0;

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const defaultValues = {
  Native: "",
  Select: "",
  RadioGroup: "",
};

export default function Inventory() {
  const { handleSubmit, register, reset, control } = useForm({ defaultValues });
  const [data, setData] = useState(null);
  const [client, setClient] = useState(null);
  const [socket, setSocket] = useState(null);
  const [session, setSession] = useState(null);
  const [dataXLS, setDataXLS] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isStaging, setIsStaging] = useState(false); // State to hold staging/production toggle value

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setDataXLS(parsedData);
    };
  };

  useEffect(() => {
    console.log("isStaging", isStaging);
    const useSSL = true; // Use SSL in production, no SSL in staging
    const serverHost = isStaging ? "stg-nakama.terraquest.xyz" : "terraquest.us-east1-c.nakamacloud.io";
    const serverPort = "443"; // Assuming the port is always 443
    const serverKey = isStaging ? "simplekey12345" : "YBmC6UK0BsS2KEOf";
  
    console.log("Using server host:", serverHost);
    console.log("Using server key:", serverKey);
  
    const client = new Client(serverKey, serverHost, serverPort, useSSL);
    setClient(client);
  
    client
      .authenticateCustom("adminTerraquest")
      .then(async (session) => {
        console.log("session", session);
        setSession(session);
        const socket = client.createSocket(useSSL ? "ws" : "ws");
        const appearOnline = true;
        await socket
          .connect(session, appearOnline)
          .then(() => {
            console.log("Connected to the WebSocket successfully!");
          })
          .catch((error) => {
            console.error("WebSocket connection error:", error);
          });
        setSocket(socket);
      })
      .catch((error) => {
        console.error("Authentication error:", error);
      });
  }, [isStaging]);
  

  const onSubmit = async (data) => {
    setData(data);

    const writeRequest = {
      collection: data.Collection,
      key: data.Key,
      permission_read: 2,
      permission_write: 1,
      user_id: null,
      value: {
        collection: dataXLS,
      },
    };

    setAllData(writeRequest);

    if (client && session) {
      try {
        await client.writeStorageObjects(session, [writeRequest]);
        toast.success("Successfully appended items", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        const keyName = { collection: data.Key };
        const results = await socket.rpc("getInventoryId", JSON.stringify(keyName));
        console.log("Result from getInventoryId:", results);
      } catch (error) {
        console.error("Error writing to storage:", error);
      }
    }
  };

  renderCount++;

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <Header renderCount={renderCount} />
        <div className="container">
          <section>

            <div class="boxData">
              <input class="Switcher__checkbox sr-only" id="io" type="checkbox"
                checked={isStaging}
                onChange={(e) => setIsStaging(e.target.checked)} />
              <label class="Switcher" for="io">
                <div class="Switcher__trigger" data-value="Production"></div>
                <div class="Switcher__trigger" data-value="Staging"></div>
              </label>
            </div>
            <label>Collection:</label>
            <input name="Collection" className="input" ref={register} />

            <label>Key:</label>
            <input name="Key" className="input" ref={register} />

            <div className="text-white w-full text-left mt-6">
              <label>Upload File:</label>
              <input
                className="input !border-0 !px-0"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </div>
          </section>
        </div>
        <ButtonsResult {...{ allData, reset, defaultValues, submitTest: () => { } }} />
        <ToastContainer />
      </form>
    </ThemeProvider>
  );
}