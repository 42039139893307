import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Inventory from "./pages/Inventory";
import ExampleForm from "./pages/ExampleForm";
import UploadVersion from "./pages/UploadVersion";
import UploadPatchNotes from "./pages/UploadPatchNotes";
import UploadCostume from "./pages/UploadCostume";
import UploadWebGL from "./pages/UploadWebgl";
import UploadExcelTest from "./pages/UploadExcelTest";

function App() {
  return (
    <>
      <div className="flex flex-row">
        <Sidebar />
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Inventory />} />
            {/* <Route exact path="/inventory" element={<Inventory />} /> */}
            <Route exact path="/excelTest" element={<UploadExcelTest />} />
            <Route exact path="/gameversion" element={<UploadVersion />} />
            <Route exact path="/patchnotes" element={<UploadPatchNotes />} />
            <Route exact path="/costumeData" element={<UploadCostume />} />
            <Route exact path="/webGL" element={<UploadWebGL />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
