import React, { useState } from "react";
import AWS from "aws-sdk";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";

const S3_BUCKET = "game.terraquest.xyz";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIA4QBGOHJV6EZIBMW3";
const SECRET_ACCESS_KEY = "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";

const s3 = new AWS.S3({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const UploadWebGL = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileInputChange = (e) => {
    setSelectedFiles(e.target.files);
  };
  const handleUpload = async () => {
    setLoading(true);
    // Find the common parent folder among selected files
    const parentFolder = findCommonParentFolder(Array.from(selectedFiles));

    const uploadPromises = Array.from(selectedFiles).map((file) => {
      // Get the relative path of the file excluding the common parent folder
      let relativePath = file.webkitRelativePath
        .replace(parentFolder, "")
        .trim();

      // Remove the file name to get the folder path
      const folderPath = relativePath.substring(
        0,
        relativePath.lastIndexOf("/")
      );

      // Upload the file to the appropriate folder
      const params = {
        Bucket: S3_BUCKET,
        Key: folderPath ? folderPath + "/" + file.name : file.name,
        Body: file,
        ContentType: file.type, // Set the ContentType to the MIME type of the file
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) reject(err);
          else resolve(data);
        });
      });
    });

    try {
      const uploadResults = await Promise.all(uploadPromises);
      console.log("Upload results:", uploadResults);

      listBuckets();
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to find the common parent folder among selected files
  const findCommonParentFolder = (files) => {
    const paths = files.map((file) => file.webkitRelativePath.split("/"));
    const minDepth = Math.min(...paths.map((path) => path.length));

    let commonPath = "";
    for (let i = 0; i < minDepth; i++) {
      const firstPath = paths[0][i];
      if (paths.every((path) => path[i] === firstPath)) {
        commonPath += firstPath + "/";
      } else {
        break;
      }
    }

    return commonPath;
  };

  const listBuckets = () => {
    const bucketParams = {
      Bucket: S3_BUCKET,
    };

    s3.listObjects(bucketParams, (err, data) => {
      if (err) {
        console.log("Error listing objects:", err);
      } else {
        setLoading(false);
        // setTimeout(() => setLoading(false), 10000); // 10초 뒤에
        toast(<p style={{ fontSize: 16 }}>Successfully Uploaded</p>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          type: "success",
        });
        console.log("Success listing objects:", data.Contents);
        setImages(data.Contents);

        setTimeout(() => window.location.reload(), 5300); // 10초 뒤에
      }
    });
  };

  return (
    <div className="my-5 py-5">
      <div className="text-[#593F3F] my-3">WebGL Upload</div>
      <input
        type="file"
        onChange={handleFileInputChange}
        directory=""
        webkitdirectory=""
        multiple
        className="text-[#593F3F]"
      />
      <button
        onClick={handleUpload}
        className="bg-[#593f3f] hover:bg-[#F8B641] text-white font-bold py-2 px-4 rounded"
      >
        Upload Folder
      </button>
      <LoadingOverlay
        active={loading}
        spinner
        text="Uploading your folder... Please Wait..."
      />
      <ToastContainer />
    </div>
  );
};

export default UploadWebGL;
